#event-statistic-form {
  #event-statistic-form-container {
    display: flex;
    justify-content: space-around;
    #period-title {
      font-weight: 700;

      &::first-letter {
        text-transform: capitalize;
      }
    }
    .duration-picker {
      display: flex;
      align-items: center;
    }

    .period-picker-container {
      display: flex;
      .period-picker {
        display: flex;
        justify-content: center;
        .custom-date-picker {
          width: 100%;
        }
      }

      div {
        display: flex;
        margin-right: 1rem;
        input {
          margin-right: 0.2rem;
        }
      }
    }
    .filter_btn {
      display: flex;
      flex-direction: column;
      padding-top: 1rem;
      btn {
        margin-bottom: 1rem;
      }
    }
    .fliter-group {
      display: flex;

      justify-content: center;
    }
    .custom-date-picker {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .custom-period-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

#key-figures-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 700;
  font-size: 1.5rem;
}

#day-report-form {
  #date-input {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
    label {
      margin-right: 0.5rem;
    }
  }

  .download-action {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    button {
      width: fit-content;
      margin-left: 1rem;
    }
  }
}
.product-group-total {
  td {
    font-weight: 700;
  }
}

@media (max-width: 765px) {
  #key-figures-container {
    flex-wrap: wrap;
    padding: 0 !important;
    div {
      min-width: 25%;
      padding: 0.5rem;
    }
  }
  #event-statistic-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-period-custom-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .period-picker {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .filter_btn {
    width: 50%;
  }
  .fliter-group {
    display: flex;
    flex-direction: column;
  }
}

.disabledOption {
  color: gray;
  background-color: rgba(0, 0, 0, 0.1);
}

.daterangepicker {
  select {
    max-height: 150px; /* Limit height of the dropdown */
    overflow-y: auto; /* Add vertical scrolling */
  }

  /* Target the option specifically if applicable */
  option {
    white-space: nowrap; /* Prevent text wrapping */
  }
}

@media (max-width: 540px) {
  .period-picker-container {
    display: flex;
    flex-direction: column;
  }
}

.ui-icon,
.ui-widget-content .ui-icon {
  background-image: url("/public/images/ui-icons_444444_256x240.png")
    /*{iconsContent}*/;
}
.ui-widget-header .ui-icon {
  background-image: url("/public/images/ui-icons_444444_256x240.png")
    /*{iconsHeader}*/;
}
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon,
.ui-button:hover .ui-icon,
.ui-button:focus .ui-icon {
  background-image: url("/public/images/ui-icons_555555_256x240.png")
    /*{iconsHover}*/;
}
.ui-state-active .ui-icon,
.ui-button:active .ui-icon {
  background-image: url("/public/images/ui-icons_ffffff_256x240.png")
    /*{iconsActive}*/;
}
.ui-state-highlight .ui-icon,
.ui-button .ui-state-highlight.ui-icon {
  background-image: url("/public/images/ui-icons_777620_256x240.png")
    /*{iconsHighlight}*/;
}
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
  background-image: url("/public/images/ui-icons_cc0000_256x240.png")
    /*{iconsError}*/;
}
.ui-button .ui-icon {
  background-image: url("/public/images/ui-icons_777777_256x240.png")
    /*{iconsDefault}*/;
}
