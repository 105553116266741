.shop-employee-mode {
  .close-suggested-product {
    position: absolute;
    top: 0px;
    right: 4px;
    font-size: 25px;
    width: 30px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    color: red;
    padding: 0 !important;
  }

  .card-box {
    padding: 10px 0 0 15px;
  }

  .product-row {
    padding-left: 5px;
    padding-right: 5px;

    .col-4,
    .col-sm-3,
    .col-xl-2 {
      padding-left: 5px;
      padding-right: 5px;
    }

    .shop-product-container {
      padding-bottom: 10px;
      width: 180px;
      height: 130px;
      max-width: 180px;
      display: inline-block;
      padding: 8px;

      .shop-product {
        width: 100%;
        height: 100%;
        transition: all 0.1s ease-in-out;

        .product-title,
        .product-price {
          margin: 0;
        }

        .product-title {
          width: 130px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3; /* Truncate after 2 lines */
          line-height: 1.5; /* Adjust to your desired line height */
          max-height: calc(3 * 1.5em);
        }

        &:focus,
        &:active {
          transform: scale(1.05);
          -webkit-filter: brightness(-10%);
          filter: brightness(90%);
          box-shadow: 0 0 0 0.2rem #c0d7f0;
        }
      }
    }
  }
}

.product-tab-group {
  margin-bottom: 0 !important;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    color: #50565d;
  }

  .active {
    font-weight: bolder;
  }
  .product-extra-button-group {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 0.5rem;
    .product-extra-button {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      a {
        color: white;
      }
    }
  }
}
