.header-and-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .header {
    width: fit-content;
  }
  #product-search-button {
    margin-right: 1rem;
  }
}

.desktop-view {
  .sort {
    cursor: pointer;
  }
  .material-icons {
    font-size: 24px;
  }

  .material-icons-asc {
    transform: rotate(180deg);
  }
}

.mobile-view {
  margin-top: 20px;
  overflow: hidden;
  ul {
    .product-detail-list-item {
      background: transparent;
    }
  }
  .list-group-item {
    padding: 5px;
    .display-lg {
      display: flex;
    }
    .product-name {
      margin-right: 5px;
      white-space: nowrap;
      max-width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .collapse-btn {
    margin-top: 5px;
  }
}
.supplier-form .card-box {
  border-top: none;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  -moz-border-radius-topleft: unset;
  -moz-border-radius-topright: unset;
  padding: 20px !important;
}

#product-search-form {
  #product-search-button {
    margin-left: 0.5rem;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 5px;
  }
  .col-md-9 {
    padding: 0;
    .card-box {
      padding: 5px;
    }
  }

  .header-and-button {
    width: 100%;
    display: flex;
    justify-content: center;

    .header-title {
      display: none;
    }

    #product-search-form {
      width: 100%;
      display: flex;
      flex-flow: no-wrap;
      justify-content: center;
      margin: 0;
    }
  }
}
