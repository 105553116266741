body[p="organizer_location_dashboard"] {
  .card-box {
    padding: 15px;
  }
}

#location-dashboard-turnover-sum {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  #turnover-title {
    font-weight: bold;
    font-size: 1.4rem;
    color: #56944c;
  }
  #turnover-title-period {
    font-size: 0.8rem;
    display: block;
    color: black;
  }
  #turnover-sum {
    font-size: 2.3rem;
    text-align: center;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 0 !important;
    color: #4d4d4d;

    @include media-breakpoint-between(sm, md) {
      flex-direction: column;
    }

    @include media-breakpoint-only(sm) {
      font-size: 1.4rem;
    }

    @include media-breakpoint-only(md) {
      font-size: 2rem;
    }

    @include media-breakpoint-only(lg) {
      font-size: 1.7rem;
    }

    span {
      font-size: 0.75rem;
      display: inline-block;
      margin-right: 1rem;
      font-weight: 700;
      margin-top: 0;
    }
  }
  #turnover-sum-ex-vat {
    font-size: 0.75rem;
    display: inline-block;
    margin-right: 1rem;
    margin-top: 0;
  }
}

#week-month-turnover-container {
  display: flex;
  width: 100%;
  height: 92%;
  padding: 0 !important;
  .weekAndMonthBarChart {
    cursor: pointer;
  }
  .highcharts-container {
    margin: 0 !important;
  }
  #bar-chart {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.location-dashboard-pie {
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
}

#location-dashboard-turnover-sum,
.location-dashboard-pie {
  .loader {
    border-color: #dcdcdc;
    border-top-color: $primary;
  }
}

.event-bar-container {
  height: max-content;
  margin-bottom: 0.5rem;
}

#variable-pie-chart-box {
  height: max-content;
  overflow: visible;
  position: relative;

  .card-box {
    margin: 0 !important;
  }
  .highcharts-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .highcharts {
      width: 52px;
      height: 52px;
    }
  }
}

.rotate-icon {
  transition: transform 0.3s ease;
}

.rotate-icon.rotated {
  transform: rotate(90deg);
}

@media (max-width: 785px) {
  .dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    #week-month-turnover-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      #text-chart,
      #bar-chart {
        width: 100%;
        align-items: center;
        justify-content: center;

        div {
          text-align: center;
        }
      }
    }

    #variable-pie-chart-box {
      height: max-content;
      overflow: visible;
      position: relative;

      .card-box {
        padding: 0 !important;
        margin: 0 !important;
        #location-dashboard-pie-payment-option,
        #location-dashboard-pie-vat {
          .highcharts-container {
            width: 100%;
            padding: 0;
          }
        }
      }
    }
  }

  .isTablet[p="organizer_location_dashboard"] {
    #content-body.organizer_location_dashboard {
      .event-bar-container {
        .table-responsive {
          #event-bar-graph,
          #event-line-graph {
            min-height: 150px !important;
          }
        }
      }

      .dashboard-container {
        margin-bottom: 0 !important;

        #location-dashboard-turnover-sum,
        #week-month-turnover-container {
          margin-bottom: 0.5rem;
        }

        #location-dashboard-turnover-sum {
          justify-content: flex-start;
          height: max-content;

          #turnover-sum {
            font-size: 1.8rem;
          }
        }

        #week-month-turnover-container {
          height: max-content;
          padding: 0.425rem 0 !important;

          .weekAndMonthBarChart {
            justify-content: baseline;
            align-items: baseline;
          }
        }

        #variable-pie-chart-box {
          height: max-content;
          overflow: visible;
          position: relative;

          .card-box {
            padding: 0 !important;
            margin: 0 !important;

            #location-dashboard-pie-payment-option {
              height: 80px;
            }
          }
        }
      }
    }

    footer {
      margin-top: 0 !important;
    }
  }
}
