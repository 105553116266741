.order-info-wrapper span {
  border: 2px solid #46a0ff;
  display: inline-block;
  padding: 10px;
}

#area-zone-seat-icon {
  cursor: pointer;
}

#area-zone-seat-link {
  cursor: pointer;
}

#cart-modal {
  @include media-breakpoint-down(sm) {
    height: 100%;
  }

  overflow: auto;

  h1 {
    font-size: 1.5rem;
    margin-top: 15px;
    margin-bottom: 0;
  }

  .material-icons {
    vertical-align: middle;
    cursor: pointer;
    font-size: 28px;
  }

  .product-price {
    display: inline-block;
  }

  .cart-price-and-amount {
    width: 165px;
  }

  #cart-products-wrapper {
    overflow-x: hidden;
  }

  .modal-body {
    overflow-y: auto;
  }

  td.product-title {
    cursor: pointer;
  }
}

#cart-modal .modal-dialog {
  @include media-breakpoint-down(sm) {
    height: 100%;
    margin: 0;
  }
}

#cart-modal .modal-content {
  border-radius: 0;
  @include media-breakpoint-down(sm) {
    height: 100%;
  }
}

#cart-term-modal {
  @include media-breakpoint-down(sm) {
    height: 100vh;
  }

  .modal-body {
    overflow-y: auto;
  }
}

#cart-term-modal .modal-dialog {
  @include media-breakpoint-down(sm) {
    margin: 0;
  }
}

#cart-term-modal .modal-content {
  border-radius: 0;
  @include media-breakpoint-down(sm) {
    height: 100vh;
  }
}

#table-order-summary {
  .product-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
  }

  .no-border {
    border: none;
  }
}

tr.product-extra td {
  border-top: none;
  font-size: 14px;
}

ul.product-extra li {
  font-size: 14px;
}

#product-extra-modal {
  z-index: 2000;

  @include media-breakpoint-down(sm) {
    height: 100vh;
    max-height: 100%;
  }

  overflow: auto;

  h1 {
    font-size: 1.5rem;
    margin-top: 15px;
    margin-bottom: 0;
  }

  #products-wrapper {
    max-height: 340px;
    overflow-x: hidden;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
}

#product-extra-modal .modal-dialog {
  @include media-breakpoint-down(sm) {
    margin: 0;
  }
}

#product-extra-modal .modal-content {
  border-radius: 0;
  @include media-breakpoint-down(sm) {
    height: 100vh;
  }
}

.pickup_reference {
  font-size: 14rem;
}

#add-discount {
  width: max-content;
}

.show-discount-percent {
  color: gray;
}

.product-qty-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.5rem;
}

.product-qty-container {
  .product-qty-button {
    i {
      font-size: 2.5rem;
    }
  }
}

#order-storage-list-modal,
#order-table-list-modal {
  .saved-identifier {
    color: white;
    background-color: #28a745;
  }
}

#cart-products-wrapper {
  .cart-price-and-amount {
    .material-icons {
      font-size: 2rem;
      cursor: pointer;
    }
  }

  .product-title {
    min-width: 110px;
    max-width: calc(110px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;

    strong,
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.classic-product-wrapper {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;

  #cart-products-wrapper {
    overflow-x: hidden;
    overflow-y: auto;

    .portrait {
      .product-title {
        vertical-align: middle;
        font-size: 0.75rem;
        min-width: inherit;
      }
    }
  }
}

.classic-pos {
  #checkout-list {
    height: calc(100% - 140px);

    #cart-wrapper {
      height: 100%;
    }
  }

  .payment-card {
    width: 150px;
    max-width: 150px;
    flex: 1;
  }
}

.cart-actions {
  flex: 0 0 auto;
}

#split-payment-cart-modal {
  .modal-dialog {
    max-width: 75%;
    padding: 1rem;
  }

  .modal-body {
    max-height: calc(100vh - 9rem);
    overflow: auto;

    #split-product-list {
      width: 100%;
      font-size: small;
      btn {
        font-size: small;
      }
      #cart-product-list {
        max-height: 50%;
      }
    }

    #cart-product-list {
      max-height: calc(100vh - 32rem);
      overflow-y: auto;
      width: 60%;
    }

    #person-product-list {
      border-radius: 0.3rem;
      width: 40%;

      h6 {
        font-size: 0.75rem;
        font-weight: bold;
      }
    }
  }

  .person-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;

    .select-person {
      border-radius: 8px;

      .product-count {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-weight: bold;
        text-align: center;
      }

      &.selected {
        border: 1px solid #67c1ff;
        box-shadow: 0 0 4px 2px #cbeaff;
        box-sizing: border-box;
      }
    }

    .select-person,
    .add-person {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: min-content;
      padding: 0.5rem;
      border: 1px;

      .product-count {
        width: 30px;
        height: 30px;
      }

      .remove-confirm-div {
        background-color: #00000041;
        display: flex;
        justify-content: center;
        align-items: center;

        .remove-confirm-content {
          background-color: white;
          width: max-content;
          padding: 20px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 0.3rem;
          outline: 0;
        }
      }

      .person-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        width: 100px;
        height: 100px;
        margin: 1rem;
        position: relative;
      }

      .close-icon-container {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate(50%, -50%);
        width: 35px;
        height: 35px;
        background-color: grey;
        border-radius: 50%;

        #close-icon {
          font-size: 20px;
          color: white;
          cursor: pointer;
        }
      }

      .name-container {
        width: 100%;
        display: flex;
        justify-content: center;

        .person-name-input {
          border: none;
          border-bottom: 1px solid #000;
          border-radius: 0;
          box-shadow: none;
          max-width: 75%;
        }

        .person-name-input:focus {
          border-bottom: 2px solid #000;
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  #person-product-list {
    border-radius: 0.3rem;

    h6 {
      font-weight: bold;
    }
  }

  .add-to-cart-container {
    min-height: 38px;
  }

  .split-product-title {
    min-width: 110px;
    max-width: calc(110px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;

    strong,
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .cart-price-and-amount {
    width: 8rem;
  }

  .modal-dialog {
    .split-product-actions {
      display: flex;

      .add-divided-product-to-person {
        width: max-content;
      }
    }

    &.mobile {
      max-width: 95%;
      padding: 0.25rem;

      .modal-body {
        max-height: calc(100vh - 5.5rem);
        overflow: auto;
      }

      .person-row {
        //remove if want to scroll horizontally
        flex-wrap: wrap;
        overflow-y: visible;
        max-height: fit-content;

        .select-person,
        .add-person {
          padding: 0.2rem;

          .product-count {
            width: 15px;
            height: 15px;
            font-size: 0.55rem;
          }

          .person-btn {
            padding: 0;
            margin: 0.75rem;
            width: 35px;
            height: 35px;
          }

          .close-icon-container {
            width: 20px;
            height: 20px;

            #close-icon {
              font-size: 10px;
            }
          }

          .name-container {
            font-size: 0.75rem;
          }
        }
      }

      #split-product-list {
        max-height: 100%;

        #cart-product-list {
          max-height: 50%;
          width: 70%;
        }
      }

      #person-product-list {
        border-radius: 0.3rem;

        h6 {
          font-size: 0.75rem;
          font-weight: bold;
        }
      }

      .add-to-cart-container {
        min-height: 38px;
      }

      .split-product-title {
        min-width: 90px;
        max-width: calc(90px);

        strong {
          font-size: 0.75rem;
        }
        p {
          font-size: 0.7rem;
        }
      }

      .cart-price-and-amount {
        padding-top: 0.5rem;
        font-size: 0.75rem;

        .text-center {
          padding-left: 0;
          padding-right: 0;

          &.product-price {
            min-width: max-content;
            width: 100%;
          }
        }
      }

      .split-actions {
        .divide-product,
        .add-to-customer,
        .product-table-reorder {
          width: fit-content;
          font-size: 0.75rem;
          padding: 0.25rem;
        }
      }

      .person-split-actions {
        padding-right: 0;
        width: 100%;

        .text-center {
          padding-right: 0;

          .remove-splited-product {
            font-size: 0.75rem;
            padding: 0.25rem;
            width: max-content;
          }
        }
      }

      #add-splited-product-to-cart {
        font-size: 0.75rem;
      }

      .product-table-group {
        .split-product-row {
          .split-product-price {
            max-width: 128px;
            min-width: max-content;

            strong {
              font-size: 0.75rem;
            }
          }

          .split-product-actions {
            justify-content: end;

            .remove-divided-product,
            .add-divided-product-to-person {
              font-size: 0.75rem;
              padding: 0.25rem;
            }
          }
        }
      }
    }
  }
}

#self-service-table-container {
  display: flex;
  flex-wrap: wrap;
  .self-service-table {
    width: 100px;
    height: 100px;
    padding: 0.2rem;
    margin: 0.2rem;
  }
}

.terminal-payment {
  width: 100px;
  height: 100px;
  padding: 0.2rem;
  margin: 0.2rem;
}

.blink {
  /* Animation properties with vendor prefixes */
  -webkit-animation: blink-animation 1s infinite; /* For Safari, Chrome */
  -moz-animation: blink-animation 1s infinite; /* For Firefox */
  -o-animation: blink-animation 1s infinite; /* For older Opera versions */
  animation: blink-animation 1s infinite; /* Standard */
}

/* Keyframes with prefixes */
@-webkit-keyframes blink-animation {
  0% {
    background-color: var(--blink-color); /* Blink color (e.g., red) */
  }
  50% {
    background-color: var(--original-bg-color); /* Original background color */
  }
  100% {
    background-color: var(--blink-color); /* Blink color (e.g., red) */
  }
}

@-moz-keyframes blink-animation {
  0% {
    background-color: var(--blink-color);
  }
  50% {
    background-color: var(--original-bg-color);
  }
  100% {
    background-color: var(--blink-color);
  }
}

@-o-keyframes blink-animation {
  0% {
    background-color: var(--blink-color);
  }
  50% {
    background-color: var(--original-bg-color);
  }
  100% {
    background-color: var(--blink-color);
  }
}

@keyframes blink-animation {
  0% {
    background-color: var(--blink-color);
  }
  50% {
    background-color: var(--original-bg-color);
  }
  100% {
    background-color: var(--blink-color);
  }
}

@media (min-width: 300px) and (max-width: 1260px) {
  #split-payment-cart-modal {
    #split-product-list {
      flex-direction: column;

      #cart-product-list {
        width: 100% !important;
        max-height: inherit !important;
        overflow: hidden !important;
      }

      .divider {
        min-height: 1.5rem;
        width: 0 !important;
      }

      #person-product-list {
        width: 100% !important;
      }
    }
  }
}

.invalid-feedback {
  position: absolute; /* Position the feedback message absolutely */
  top: 100%; /* Place it directly below the input */
  left: 0; /* Align it with the input */
  margin-top: 0.25rem; /* Optional: Adjust spacing below the input */
  font-size: 0.875rem; /* Adjust feedback text size */
  color: #dc3545; /* Use Bootstrap's invalid feedback color */
  display: block; /* Ensure visibility */
  z-index: 1; /* Ensure the feedback is above other elements */
}
