#show-event {
  #event-description {
    position: relative;

    &.view-all {
      @include media-breakpoint-down(sm) {
        max-height: none;
      }

      #fadeout-button-show,
      .fadeout {
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      max-height: 200px;
      overflow: hidden;
    }

    .fadeout {
      position: absolute;
      bottom: 35px;
      left: 0;
      height: 140px;
      width: 100%;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgb(255, 255, 255) 100%
      );
    }

    #fadeout-button-show {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      background-color: #ffffff;
      padding-bottom: 10px;

      #fadeout-button {
        font-size: 1rem;
      }
    }
  }
}

#event-list {
  .event-list-image-container {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .refunded {
    font-weight: bold;
    color: #cc0000;
  }

  div.desktop-view {
    table {
      td {
        div.event-actions {
          .dropdown-menu {
            text-align: center;
          }

          .dropdown .material-icons {
            color: #000;
            font-size: 32px;
          }
        }
      }
    }
  }
}

div#admin-content-frame {
  div#admin-content {
    div.welcome-message {
      font-weight: bold;
      text-align: center;
      color: #999;
      
      @include media-breakpoint-down(sm) {
        font-size: 1rem;
      }
      @include media-breakpoint-up(sm) {
        font-size: 1.5rem;
      }
    }
  }
}

.event-subevent-calendar-box {
  margin-right: 30px;

  &-icon {
    position: absolute;
  }
}

.event-subevent-list {
  &-few-ticket-left {
    color: #ffaa00;
  }

  &-sold-out {
    color: #ff0000;
  }
}

#subevent-list {
  .refunded {
    font-weight: bold;
    color: #cc0000;
  }
}

#event_statistic_form {
  .material-icons {
    color: #000;
    font-size: 32px;
    cursor: pointer;
  }
}

#location-opening-hours-exception-list {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

#location-information-modal .modal-dialog {
  @include media-breakpoint-down(sm) {
    margin: 0;
  }
}

#location-information-modal .modal-content {
  border-radius: 0;
  overflow: auto;
  @include media-breakpoint-down(sm) {
    height: 100vh;
  }
}

#location-information-modal {
  @include media-breakpoint-down(sm) {
    height: 100vh;
  }

  overflow: auto;

  h1 {
    font-size: 1.5rem;
    margin-top: 15px;
    margin-bottom: 0;
  }
}

.location-information-modal-day {
  display: inline-block;
  width: 70px;
}

.location-information-modal-exception {
  display: inline-block;
  width: 100px;
}

.location-closed {
  color: #bd2130;
}

.location-open {
  color: #1c7430;
}

#location-cart-summary {
  height: 60px;
  color: #ffffff;
  background: $primary;
  z-index: 1000;
  
  @include media-breakpoint-up(md) {
    height: 100px;
    font-size: 2rem;
  }
}

.has-saved-table {
  bottom: 6% !important;
}

.scale-up {
  font-size: 2.75rem !important; /* Increase the font size */
  transition: font-size 0.1s ease-in-out; /* Smooth transition */
  text-shadow: 2px 2px 3px black;
  font-weight: 900;
}
.scale-down {
  font-size: 2rem !important;
  transition: font-size 0.1s ease-in-out; /* Smooth transition */
}

.cart-open {
  cursor: pointer;
}

#location-cart-summary {
  div.row {
    @include media-breakpoint-up(md) {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
      margin-bottom: 1rem !important;
    }
    @include media-breakpoint-down(sm) {
      margin: 1rem !important;
    }

    div {
      @include media-breakpoint-up(md) {
        margin-top: 20px !important;
      }
    }

    .cart-quick-payment-terminal {
      @include media-breakpoint-down(sm) {
        .btn {
          padding: 0.25rem 0.5rem;
          font-size: 0.875rem;
          line-height: 1.5;
          border-radius: 0.2rem;
          margin-top: -12px;
        }
      }

      @include media-breakpoint-up(md) {
        .btn {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }
}

#location-cart-summary .material-icons {
  vertical-align: middle;
}

#order_search_form .datepicker-input {
  @include media-breakpoint-up(sm) {
    width: 150px;
  }
}

#order_search_form .alert-lightbulb {
  color: $danger;
}

table#order-list-table tr.new-order {
  border: 2px solid #dc3545;
  box-sizing: border-box;
}

table#order-list-table,
table#order-list-table .btn {
  font-size: 1.2rem;
}

@include media-breakpoint-down(lg) {
  #ticket-list {
    padding: 10px;
  }
  .order-list-order-td {
    min-width: unset !important;
  }
  .order-status,
  .order-product-status {
    text-align: left !important;
    margin-top: 5px;
  }
  table#order-list-table.table thead th {
    border-top: none;
  }
  table#order-list-table.table .order-status {
    text-align: left !important;
  }

  #ticket-list table#order-list-table.table .btn-round-corners {
    border-radius: 0.2rem;
  }
}

@include media-breakpoint-up(xl) {
  #ticket-list table#order-list-table.table {
    .btn-round-corners-non-mobile {
      border-radius: 0.2rem;
    }

    .order-list-table-number-td {
      //min-width: 100px;
    }

    .order-list-order-date-td {
      //min-width: 150px;
    }
  }
}

.loader {
  border: 3px solid #ffffff;
  border-radius: 50%;
  border-top: 3px solid $success;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  display: inline-block;
  margin-left: 3px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.statistic-trigger {
  max-width: 200px;
}

form#location-promo-code-form {
  #promo_code {
    @include media-breakpoint-down(sm) {
      max-width: 160px;
    }
  }
}

#banner-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 700px) {
  .scale-down {
    font-size: 1.5rem !important;
  }
  .scale-up {
    font-size: 1.75rem !important;
  }
}

.mic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  .mic-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: 0.5s;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);

    i {
      color: #b2b1b1;
      font-size: 23px;
      transition: 0.9s;
    }

    &:before {
      content: "";
      width: 50px;
      height: 50px;
      border-radius: 50%;
      opacity: 0.2;
      z-index: -1;
      position: absolute;
    }
  }

  .mic-circle.active {
    background: #ff0000;

    &:before {
      background: gray;
      animation: bounce 0.8s ease-in-out infinite 0.5s;
    }

    i {
      color: #ffffff;
    }
  }
}

@keyframes bounce {
  0% {
    transform: scale(1.25);
  }
  25% {
    transform: scale(1.5);
  }
  75% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1.5);
  }
}
