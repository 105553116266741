#product-category-list {
  .material-icons {
    cursor: pointer;
  }
}

.menu-container {
  .nav-title {
    color: black !important;
  }
}

.product-category-link-select {
  width: 300px;
}

.product-category-link-delete-button {
  cursor: pointer;
}

#navbar-product-category {
  width: 100%;
  position: sticky;
  top: 0;

  //   @include media-breakpoint-only(sm) {
  //     width: 540px;
  //   }
  //   @include media-breakpoint-only(md) {
  //     width: 720px;
  //   }
  //   @include media-breakpoint-only(lg) {
  //     width: 960px;
  //   }
  //   @include media-breakpoint-only(xl) {
  //     width: 1140px;
  //   }
  z-index: 100;
}

.product-category-title {
  @include media-breakpoint-down(sm) {
    margin-left: 15px;
  }
}

#product-category-list #deactivated-product-category li.list-group-item {
  background-color: #edf2f6;
}

.product-category-product-tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: safe center;

  .product-color-picker {
    cursor: pointer;
  }
}

#admin-content-frame {
  ul#product-category-link-list-sortable {
    li.product-category-link {
      word-break: break-all;
    }
  }
}

.product-extra-tab {
  display: flex;
  justify-content: flex-end;
}

#background-color-warning {
  z-index: 1999;
  background-color: rgba($color: #000000, $alpha: 0.3);
}

.product-list {
  overflow: hidden;
  .product-category-nav {
    display: flex;
    max-width: 100%;
    overflow-x: auto; // Enable horizontal scrolling

    .nav {
      display: flex;
      width: max-content;
      overflow-x: auto; // Enable horizontal scrolling
      padding: 1rem;
      /* Webkit Scrollbar styles */
      &::-webkit-scrollbar {
        height: 2px; // Decreased height for a thinner scrollbar
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 2px; // Adjusted radius for a smaller thumb
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      /* Firefox Scrollbar styles */
      scrollbar-width: thin;
      scrollbar-color: #888 #f1f1f1;

      .classic-category-nav-link {
        cursor: pointer;
        text-align: center;
        padding: 0.5rem 1rem;
        display: inline-block;
        border-radius: 8px;
        margin: 0 4px 4px 4px;
        border: 1px solid #ddd;
        transition: background-color 0.2s, border-color 0.3s;

        &:hover {
          color: white;
          background-color: #007bff;
        }
      }
    }
  }

  .nav-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;

    .dropdown-menu {
      min-width: 300px;
      position: absolute;
      left: -80%;
      transform: translateX(-50%);
      top: 2.75rem;
      max-width: 300px;
      max-height: 400px;
      overflow-y: auto; /* Always show vertical scrollbar */
      overflow-x: hidden;

      .notification-header {
        font-weight: 700;
      }

      .dropdown-list {
        margin: 0 0 1rem 0 !important;
        padding: 0 !important;

        :hover {
          background-color: #0069d9;
          color: white;
        }

        .dropdown-item {
          font-size: small;
          display: flex;
          flex-direction: column;
          border-radius: 5px;

          .notification-text {
            font-weight: 700;
            pointer-events: none !important;
          }
        }
      }
    }

    .actions {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .product-pagination {
    user-select: none;

    .icon-container {
      display: flex;
      margin-right: 0.75rem;

      div {
        width: 32px;
      }

      .material-icons {
        font-size: 32px;
        width: 32px;
        cursor: pointer;
      }
    }
  }

  .products {
    display: grid;
    grid-template-columns: repeat(auto-fill, 180px);
    grid-template-rows: repeat(auto-fill, 125px);
    height: calc(100% - 166.15px);
    overflow-x: hidden;
    overflow-y: auto;

    .shop-product-container {
      width: 180px;
      height: 130px;
      max-width: 180px;
      display: inline-block;
      padding: 8px;

      .shop-product {
        width: 100%;
        height: 100%;
        transition: all 0.1s ease-in-out;

        .product-title,
        .product-price {
          margin: 0;
        }

        .product-title {
          width: 130px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3; /* Truncate after 2 lines */
          line-height: 1.5; /* Adjust to your desired line height */
          max-height: calc(3 * 1.5em);
        }

        &:focus,
        &:active {
          transform: scale(1.05);
          -webkit-filter: brightness(-10%);
          filter: brightness(90%);
          box-shadow: 0 0 0 0.2rem #c0d7f0;
        }
      }
    }

    &.portrait {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      grid-template-rows: repeat(auto-fill, 105px);

      .shop-product-container {
        width: 140px;
        height: 101px;
        max-width: 140px;
        padding: 4px;

        .shop-product {
          .product-title,
          .product-price {
            margin: 0;
          }

          .product-title {
            font-size: 0.75rem;
            width: 100%;
          }

          .product-price {
            font-size: 0.75rem;
            width: 100%;
          }
        }
      }
    }
  }

  .suggestion-products {
    display: grid;
    grid-template-columns: repeat(auto-fill, 180px);
    grid-template-rows: repeat(auto-fill, 125px);
    height: calc(100% - 166.15px);
    overflow-x: hidden;
    overflow-y: auto;

    &.suggested-products {
      grid-template-columns: repeat(auto-fill, 180px);
      grid-template-rows: repeat(2, auto);
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 260px;
    }

    .shop-product-container {
      width: 180px;
      height: 130px;
      max-width: 180px;
      display: inline-block;
      padding: 8px;

      .shop-product {
        width: 100%;
        height: 100%;
        transition: all 0.1s ease-in-out;

        .product-title,
        .product-price {
          margin: 0;
        }

        .product-title {
          width: 130px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3; /* Truncate after 2 lines */
          line-height: 1.5; /* Adjust to your desired line height */
          max-height: calc(3 * 1.5em);
        }

        &:focus,
        &:active {
          transform: scale(1.05);
          -webkit-filter: brightness(-10%);
          filter: brightness(90%);
          box-shadow: 0 0 0 0.2rem #c0d7f0;
        }
      }
    }

    &.portrait {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      grid-template-rows: repeat(auto-fill, 105px);

      .shop-product-container {
        width: 140px;
        height: 101px;
        max-width: 140px;
        padding: 4px;

        .shop-product {
          .product-title,
          .product-price {
            margin: 0;
          }

          .product-title {
            font-size: 0.75rem;
            width: 100%;
          }

          .product-price {
            font-size: 0.75rem;
            width: 100%;
          }
        }
      }
    }
  }
}

.classic-pos {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
}

.category-list {
  .nav-pills {
    .category-nav-link {
      width: max-content;
    }
  }
}

.shop-product-container {
  .shop-product {
    transition: all 0.1s ease-in-out;

    &:focus,
    &:active {
      transform: scale(1.05);
      -webkit-filter: brightness(-10%);
      filter: brightness(90%);
      box-shadow: 0 0 0 0.2rem #c0d7f0;
    }
  }
}
